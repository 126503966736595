<template>
  <div class="pt-16">
    <section class="bg-gray-100 bg-opacity-50 p-2 h-screen w-full">
      <div class="mx-auto container max-w-5xl bg-gray-300 shadow-md">
        <div
          class="bg-gray-100 p-4 border-t-4 bg-opacity-5 border-green-500  rounded-t"
        >
          <div class="max-w-sm mx-auto md:w-full md:mx-0">
            <div class="inline-flex items-center space-x-4">
              <!-- <img
                class="w-10 h-10 object-cover rounded-full"
                alt="User avatar"
                src="https://www.flaticon.com/svg/static/icons/svg/2922/2922539.svg"
              /> -->
              <svg class="fill-current mr-1" width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd"><path d="M12 0c6.623 0 12 5.377 12 12s-5.377 12-12 12-12-5.377-12-12 5.377-12 12-12zm8.127 19.41c-.282-.401-.772-.654-1.624-.85-3.848-.906-4.097-1.501-4.352-2.059-.259-.565-.19-1.23.205-1.977 1.726-3.257 2.09-6.024 1.027-7.79-.674-1.119-1.875-1.734-3.383-1.734-1.521 0-2.732.626-3.409 1.763-1.066 1.789-.693 4.544 1.049 7.757.402.742.476 1.406.22 1.974-.265.586-.611 1.19-4.365 2.066-.852.196-1.342.449-1.623.848 2.012 2.207 4.91 3.592 8.128 3.592s6.115-1.385 8.127-3.59zm.65-.782c1.395-1.844 2.223-4.14 2.223-6.628 0-6.071-4.929-11-11-11s-11 4.929-11 11c0 2.487.827 4.783 2.222 6.626.409-.452 1.049-.81 2.049-1.041 2.025-.462 3.376-.836 3.678-1.502.122-.272.061-.628-.188-1.087-1.917-3.535-2.282-6.641-1.03-8.745.853-1.431 2.408-2.251 4.269-2.251 1.845 0 3.391.808 4.24 2.218 1.251 2.079.896 5.195-1 8.774-.245.463-.304.821-.179 1.094.305.668 1.644 1.038 3.667 1.499 1 .23 1.64.59 2.049 1.043z"/></svg>
              <h1 class="text-gray-700 font-semibold capitalize"></h1>
            </div>
          </div>
        </div>

        <div class="bg-white space-y-6">
          <div v-if="messages" class="alert mx-2 alert-outline-warning flex items-center mb-2 show" role="alert" style="display: flex;">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="bx-burst lucide w-6 h-6 mr-2"><circle cx="12" cy="12" r="10"></circle><line x1="12" y1="8" x2="12" y2="12"></line><line x1="12" y1="16" x2="12.01" y2="16"></line></svg>
             {{messages}}
           </div>
          <div
            class="md:inline-flex md:s
            pace-y-0 w-full p-2 text-gray-600 items-center"
          >
            <div class="md:w-2/3 max-w-sm mx-auto">
              <label class="text-sm text-gray-500">{{$t('email')}}</label>
              <div class="w-full rounded-md inline-flex border">
                <div class="pt-2 rounded-md w-1/12 bg-gray-100">
                  <i class="material-icons px-1">mail</i>
                </div>
                <span class="p-2">{{ user.email }}</span>
              </div>
            </div>

            <div class="md:w-2/3 max-w-sm mx-auto">
              <label class="text-sm text-gray-500">{{$t('role')}}</label>
              <div class="w-full rounded-md inline-flex border">
                <div class="pt-2 rounded-md w-1/12 bg-gray-100">
                  <i class="material-icons px-1">lock</i>
                </div>
                <input
                  type="text"
                  v-model="user.role.name"
                  class="w-11/12 focus:outline-none rounded-md bg-white focus:text-gray-600 p-2"
                  placeholder="role"
                  disabled
                />
              </div>
            </div>
          </div>

          <div
            class="md:inline-flex md:space-y-0 w-full p-1 text-gray-600 items-center"
          >
            <div class="md:w-2/3 max-w-sm mx-auto">
              <label class="text-sm text-gray-500"> {{$t('fullname')}}</label>
              <div class="w-full rounded-md inline-flex border">
                <div class="pt-2 rounded-md w-1/12 bg-gray-100">
                  <i class="material-icons px-1">person</i>
                </div>
                <input
                  type="text"
                  v-model="user.fullName"
                  class="w-11/12 focus:outline-none rounded-md bg-white focus:text-gray-600 p-2"
                  placeholder="fullName"
                />
              </div>
            </div>

            <div class="md:w-2/3 max-w-sm mx-auto">
              <label class="text-sm text-gray-500"> {{$t('phone')}}</label>
              <div class="w-full rounded-md inline-flex border">
                <div class="pt-2 rounded-md w-1/12 bg-gray-100">
                  <i class="material-icons px-1">person</i>
                </div>
                <input
                  type="text"
                  v-model="user.phone"
                  class="w-11/12 focus:outline-none rounded-md bg-white focus:text-gray-600 p-2"
                  placeholder="phone"
                />
              </div>
            </div>
          </div>

          <div
            class="md:inline-flex md:space-y-0 w-full p-1 text-gray-600 items-center"
          >
            <div class="md:w-2/3 max-w-sm mx-auto">
              <label class="text-sm text-gray-500"> {{$t('username')}}</label>
              <div class="w-full rounded-md inline-flex border">
                <div class="pt-2 rounded-md w-1/12 bg-gray-100">
                  <i class="material-icons px-1">person</i>
                </div>
                <input
                  type="text"
                  v-model="user.username"
                  class="w-11/12 focus:outline-none rounded-md bg-white focus:text-gray-600 p-2"
                  placeholder="username"
                  @keydown.space="(event) => event.preventDefault()"
                  @keydown="nameKeydown($event)"
                  :disabled="isDisabled"
                />
              </div>
            </div>

            <div class="md:w-2/3 max-w-sm mx-auto">
              <label class="text-sm text-gray-500">{{$t('store')}}</label>
              <div class="w-full rounded-md inline-flex border">
                <div class="pt-2 rounded-md w-1/12 bg-gray-100">
                  <i class="material-icons px-1">business</i>
                </div>
                <input
                  type="text"
                  v-model="user.company"
                  class="w-11/12 focus:outline-none rounded-md bg-white focus:text-gray-600 p-2"
                  placeholder="company"
                />
              </div>
            </div>
          </div>
         <div v-if="user.type === 'Seller'" class="md:inline-flex md:space-y-0 w-full p-1 text-gray-600 items-center">
               <div class="md:w-2/3 max-w-sm mx-auto">
              <label class="text-sm text-gray-500">{{$t('RIB OR WISE OR IBAN ...')}}</label>
              <div class="w-full rounded-md inline-flex border">
                <div class="pt-2 rounded-md w-1/12 bg-gray-100">
                  <i class="material-icons px-1">call_to_action</i>
                </div>
                <input
                  type="text"
                  v-model="user.rib"
                  class="w-11/12 focus:outline-none rounded-md bg-white focus:text-gray-600 p-2"
                  :placeholder="$t('rib')"
                />
              </div>
            </div>
               <div class="md:w-2/3 max-w-sm mx-auto"></div>
         </div>
          <hr />

          <div
            class="md:inline-flex w-full space-y-4 md:space-y-0 justify-center text-gray-600 items-center"
          >
            <span class="text-gray-700 font-semibold">{{$t('change_password')}} </span>
            <div class="md:w-1/12 justify-end text-center md:pl-6">
              <button
                class="text-white hover:bg-green-600  rounded-full bg-green-600  flex items-center focus:outline-none p-2 ml-2"
              >
                <i class="material-icons">keyboard_arrow_up</i>
              </button>
            </div>
          </div>

          <hr />

          <div
            class="md:inline-flex w-full space-y-4 md:space-y-0 p-4 text-gray-600 items-center"
          >
            <div
              class="md:w-5/12 w-full md:pl-9 max-w-sm mx-auto space-y-5 md:inline-flex pl-2"
            >
              <div class="w-full inline-flex border-b hover:border-green-500 ">
                <div class="w-1/12 pt-2">
                  <svg
                    fill="none"
                    class="w-6 text-gray-500 mx-auto"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"
                    />
                  </svg>
                </div>
                <input
                  type="password"
                  v-model="user.password"
                  class="w-11/12 focus:outline-none focus:text-gray-600 p-2 ml-4"
                  placeholder="New password"
                />
              </div>
            </div>

            <div
              class="md:w-5/12 w-full md:pl-9 max-w-sm mx-auto space-y-5 md:inline-flex pl-2"
            >
              <div class="w-full inline-flex border-b hover:border-green-500 ">
                <div class="w-1/12 pt-2">
                  <svg
                    fill="none"
                    class="w-6 text-gray-500 mx-auto"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"
                    />
                  </svg>
                </div>
                <input
                  type="password"
                  v-model="user.confirmPassword"
                  class="w-11/12 focus:outline-none focus:text-gray-600 p-2 ml-4"
                  placeholder="Confirm password"
                />
              </div>
            </div>
          </div>
          <hr />
          <div
            class="md:inline-flex w-full space-y-4 justify-center md:space-y-0 p-2 text-gray-600 items-center"
          >
            <div class="md:w-3/12 text-center md:pl-6 pb-8">
              <button
              :disabled="loading"
                @click="update"
                class="text-white w-full mx-auto max-w-sm rounded text-center bg-green-600  py-2 px-8 inline-flex items-center focus:outline-none md:float-right"
              >
                <i class="material-icons mr-8">save </i>
                {{$t('save')}}
              </button>
            </div>
          </div>

          <div v-if="user.status == 'inactive'" class="ml-20 mr-20 pb-20">
            <div
              class="alert flex flex-row items-center bg-yellow-200 p-5 rounded border-b-2 border-yellow-300"
            >
              <div
                class="alert-icon flex items-center bg-yellow-100 border-2 border-yellow-500 justify-center h-10 w-10 flex-shrink-0 rounded-full"
              >
                <span class="text-yellow-500">
                  <svg fill="currentColor" viewBox="0 0 20 20" class="h-6 w-6">
                    <path
                      fill-rule="evenodd"
                      d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </span>
              </div>
              <div class="alert-content ml-4">
                <div class="alert-title font-semibold text-lg text-yellow-800">
                    {{$t('warning')}}
                </div>
                <div class="alert-description text-sm text-yellow-600">
                   {{$t('must_shange_pass')}}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import DivFilter from "../components/DivFilter.vue";
export default {
  components: { DivFilter },
  name: "Profil",
  props: {
     currentUser: { type: Object},
   },
  data() {
    return {
      user: {
        role: {},
      },
      isDisabled: false,
      show: false,
      loading:false,
      messages:'',
    };
  },
  async mounted() {
    this.token = this.$jwtService.verifyToken();
    this.getUser();
  },
  methods: {
    async getUser() {
      this.user=this.currentUser;
      if(this.user.username) this.isDisabled = true;
      this.checkUserMessage();

      // const res = await this.$server.me("users");
      // if (res.content) {
      //   if (res.content.username) {
      //     this.isDisabled = true;
      //   }
      //   this.user = res.content;
      // }
    },
    checkUserMessage(){
       this.messages='';
       if(this.user.type === "Seller" && (!this.user.fullName || !this.user.rib)){
          if(!this.user.fullName) this.messages='full Name is required ';
          if(!this.user.rib){
               if(!this.user.fullName) this.messages +='&& ';
               this.messages +='RIB is required ';
          } 
        }
    },
    showBar() {
      if (this.show) this.show = false;
      else this.show = true;
    },
    async update() {
      //console.log(this.user)
      if(this.checkData()){
          this.$confirm("Are you sure ?").then(async (res) => {
            if (res) {
            // console.log("HIIIII JE SUIS USER", this.user);
              
              if(this.user.password) this.user.password=this.user.password.trim();
              if(this.user.confirmPassword) this.user.confirmPassword=this.user.confirmPassword.trim();
              
            //  if (this.user.status == "inactive" && this.user.password) {
              if (this.user.password) {
                if (this.checkPassword()) {
                // this.user.status = "active";
                  this.loading=true;
                  const data = await this.$server.update("users", this.user);
                  this.loading=false;
                  if (data && data._id) {
                    this.$toast.success("Profil updated")
                    this.logout();
                    this.user.password = "";
                    this.user.confirmPassword = "";
                  }else this.$toast.warning(data);
                }
              } else {
                this.loading=true;
                const data = await this.$server.update("users", this.user);
                this.loading=false;
                if (data && data._id) {
                  this.$toast.success("Profil updated");
                  this.checkUserMessage();
                  this.$store.dispatch("actionSeller",{user:this.user}); 
                  this.user.password = "";
                  this.user.confirmPassword = "";
                }else this.$toast.warning(data);
              }
            }
          });
    }else this.$toast.warning("You must fill all the fields !!")
    },
    checkPassword() {
      if (this.user.confirmPassword && this.user.password && this.user.password === this.user.confirmPassword) {
        return true;
      } else {
        alert("Confirmation password does not match ", "warning");
        return false;
      }
    },
    checkData() {
      if(this.user.type === "Seller"){
         if(this.user.rib &&  this.user.email  && this.user.fullName) return true;
         else return false;
      }else{
         if(this.user.email) return true;
         else return false;
      }
    },
    logout() {
      this.$jwtService.destroyToken();
      location.href = "/";
    },
    nameKeydown(e) {
      if (/^\W$/.test(e.key)) {
        e.preventDefault();
      }
    },
  },
};
</script>


<style scoped>
.align-middle {
  vertical-align: middle !important;
}
.bx-rotate-90 {
  transform: rotate(90deg);
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=1)";
}
.bx-burst, .bx-burst-hover:hover {
  animation: burst 1.5s infinite linear;
}
.alert-outline-warning {
  --tw-border-opacity: 1;
  border-color: #f97316;
  --tw-text-opacity: 1;
  color: #f97316;
}
.alert {
  position: relative;
  border-radius: .375rem;
  border-width: 1px;
  padding: 1rem 1.25rem;
}
.icon::after {
  content: "";
  display: block;
  position: absolute;
  border-top: 23px solid transparent;
  border-bottom: 17px solid transparent;
  border-left: 12px solid #3182ce;
  left: 100%;
  top: 0;
}
@keyframes burst {
 0% {
  transform:scale(1);
  opacity:1
 }
 90% {
  transform:scale(1.5);
  opacity:0
 }
}
</style>


